// recordingAudioSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import type { TRecordingDetailsType } from "../types/index.type";
import { NoteType } from "../domain/notes.domain";

// Define a type for the slice state
type RecordingState = {
  recordingDetail: TRecordingDetailsType;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  isRecording: boolean;
  isPaused: boolean;
  mediaRecorder: MediaRecorder | null;
  recordMimeType?: string;
}

// Initial state
const initialState: RecordingState = {
  recordingDetail: {
    title: "",
    note_type: undefined,
    category_type: 0,
    modality_type: "audio",
    gender_type: 0,
    language_type: 0,
    description: "",
    fileList: [],
  },
  status: "idle",
  error: null,
  isRecording: false,
  isPaused: false,
  mediaRecorder: null,
  recordMimeType: "audio/webm",
};

// Async thunk action
export const fetchRecordings = createAsyncThunk(
  "recordings/fetchRecordings",
  async () => {
    const response = await axios.get("/api/recordings"); // Replace with your actual API endpoint
    return response.data;
  },
);

// Slice
const recordingAudioSlice = createSlice({
  name: "recordings",
  initialState,
  reducers: {
    setRecordingDetail: (state, action) => {
      state.recordingDetail = action.payload;
    },
    setMediaRecorder: (state, action) => {
      state.mediaRecorder = action.payload;
    },
    setIsRecording: (state, action) => {
      state.isRecording = action.payload;
    },
    setIsPaused: (state, action) => {
      state.isPaused = action.payload;
    },
    setRecordMimeType: (state, action) => {
      state.recordMimeType = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRecordings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRecordings.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(fetchRecordings.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? "An unknown error occurred";
      });
  },
});

// Export actions
export const {
  setRecordingDetail,
  setMediaRecorder,
  setIsRecording,
  setIsPaused,
  setRecordMimeType,
} = recordingAudioSlice.actions;

export default recordingAudioSlice.reducer;
