import { Checkbox, Divider, Input, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import styles from "./AITemplateModal.module.scss";
import { AITemplateModalProps } from "./types";
import { NOTE_TEMPLATE } from "./constants";

const Create = ({ setSelectedTab, setIsPrimaryButtonDisabled, setCurrentTemplate, isEditing, currentTemplate, setIsEditing }: AITemplateModalProps &
{ setCurrentTemplate: any, isEditing: boolean, currentTemplate: any, setIsEditing: (value: boolean) => void }) => {
  const componentName = "ai-template-modal";
  const [template, setTemplate] = React.useState({
    name: isEditing ? currentTemplate?.name : '',
    document: isEditing ? currentTemplate?.document : ''
  });
  const [showTutorial, setShowTutorial] = React.useState(false);
  const [is_addon, setIsAddon] = React.useState(currentTemplate?.settings?.is_addon || false);
  const [is_cpt_codes, setIsCptCodes] = React.useState(currentTemplate?.settings?.is_cpt_codes || false);

  React.useEffect(() => {
    const tutorialState = localStorage.getItem('tutorialState');
    if (tutorialState) {
      setShowTutorial(tutorialState === 'true');
    } else {
      setShowTutorial(true);
    }
  }, []);

  React.useEffect(() => {
    if (setIsPrimaryButtonDisabled) {
      setIsPrimaryButtonDisabled(template.name?.length === 0 || template.document?.length === 0);
    }
  }, [template, setIsPrimaryButtonDisabled]);

  React.useEffect(() => {
    setCurrentTemplate((prev: any) => ({
      ...prev,
      name: template.name,
      document: template.document,
      settings: {
        is_addon: is_addon,
        is_cpt_codes: is_cpt_codes
      }
    }));
  }, [template.name, template.document, is_addon, is_cpt_codes]);

  return (
    <div style={{ height: 'calc(100vh - 60px)', display: 'flex', flexDirection: 'column' }}>
      <div className={styles[`${componentName}__header`]}>
        <div className={styles[`${componentName}__header-container`]}>
          <button onClick={() => { setSelectedTab('list'); setCurrentTemplate(null); setIsEditing(false) }} className={styles[`${componentName}__header-close-button`]}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
            </svg>
          </button>

          <h3>{isEditing ? "Edit" : "Create"} custom AI templates</h3>
          </div>
      </div>

      <Divider style={{ marginBlock: "0" }} />

      {showTutorial && (
        <div className={styles[`${componentName}__tutorial-container`]}>
          <div>
            <div>
              <a href="https://app.arcade.software/share/baV4QWBHoI5GR4unEoMV" target="_blank" rel="noopener noreferrer">
                <img src="/assets/tutorial.png" alt="tutorial" width={57} height={40} />
              </a>
              <div>
                <h4>How to create custom AI template?</h4>
                <p>Watch our 3 mins tutorial and create AI templates in just 2 easy steps.</p>
              </div>
            </div>
            <button onClick={() => {
              localStorage.setItem('tutorialState', 'false');
              setShowTutorial(false);
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      )}

      <div className={styles[`${componentName}__create-container`]} style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div>
          <Typography.Text>Template name</Typography.Text>
          <Input
            className={`${styles[`${componentName}__create-name`]}`}
            placeholder="Type template name"
            value={template.name}
            onChange={(e) => setTemplate(prev => ({ ...prev, name: e.target.value }))}
          />
        </div>
        <div className={styles[`${componentName}__create-description`]} style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography.Text>Paste your ideal note or any document which you'd like to turn into a template</Typography.Text>
          <a href="https://getnudgeai.com/custom-template" target="_blank" rel="noopener noreferrer" style={{marginTop: '4px'}}>Learn more from examples</a>
          <TextArea
            placeholder={NOTE_TEMPLATE}
            style={{ flex: 1 }}
            value={template.document}
            onChange={(e) => setTemplate(prev => ({ ...prev, document: e.target.value }))}
          />
        </div>
        <div className={styles[`${componentName}__create-checkbox`]}>
          <Checkbox
            onChange={(e) => {
              setIsCptCodes(e.target.checked);
            }}
            checked={is_cpt_codes}
          >
            Include Nudge's suggested CPT codes for Psychiatry
          </Checkbox>
          <Checkbox
            onChange={(e) => {
              setIsAddon(e.target.checked);
            }}
            checked={is_addon}
          >
            Include Nudge's Psychotherapy Add-on code and justification
          </Checkbox>
        </div>
      </div>
    </div>
  )
}

export default Create;