import { GoogleOAuthProvider } from "@react-oauth/google";
import { ConfigProvider } from "antd";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import "./App.css";
import LoadingModal from "./components/LoadingModal";
import { useRootPath } from "./hooks/app.hook";
import { useAppDispatch } from "./hooks/redux-hooks";
import AuthLayout from "./layouts/AuthLayout";
import DefaultLayout from "./layouts/DefaultLayouts";
import UnprotectedHeaderLayout from "./layouts/UnprotectedHeaderLayout";
import AnalyticsPage from "./pages/analytics/analytics.page";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/forget-password.page";
import PasswordResetForm from "./pages/auth/reset-password.page";
import Demo from "./pages/demo/demo.page";
import Baa from "./pages/docs/baa.component";
import Privacy from "./pages/docs/privacy.component";
import Terms from "./pages/docs/terms.component";
import AllNotes from "./pages/home/AllNotes.component";
import ClientNotes from "./pages/home/ClientNotes.component";
import NotesForMobile from "./pages/home/NotesForMobile.component";
import HomePage from "./pages/home/home.page";
import ThankYou from "./pages/payment/ThankYou";
import { getUserInfo } from "./service/auth.service";
import { setUserInfo as setUserInfoApp } from "./slices/authSlice";
import { fetchUserData } from "./slices/userSlice";
import "./styles/global.scss";
import { theme } from "./theme/ant-theme";
import { initAnalytics } from "./utils/analytics";
import { INTERCOM_APP_ID } from "./utils/constants";
import Agent from "./pages/agents/Agent";

const GOOGLE_CLIENT_ID =
  "24618415969-sisnrs3hseguen2scue1bdroiton56mo.apps.googleusercontent.com";

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { rootPath } = useRootPath();

  const [loading, setLoading] = useState(true);

  const onFetchUserInfo = async () => {
    try {
      const response = await getUserInfo();
      const data = await response.data;
      dispatch(setUserInfoApp(data));
      return data;
    } catch (error) {
      console.error("Error fetching user info:", error);
    } finally {
      setLoading(false);
    }
  };

  // biome-ignore lint: no need to add onFetchUserInfo to the dependency array
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(fetchUserData());
      onFetchUserInfo();
      if (location.pathname === "/" || location.pathname === "/demo") {
        navigate(rootPath);
      }
    } else if (
      location &&
      (location.pathname === "/" ||
        location.pathname === "/home" ||
        location.pathname === "/onboarding")
    ) {
      navigate("/register");
      setLoading(false);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.pathname]);

  useEffect(() => {
    initAnalytics();
  }, []);

  if (loading) return <LoadingModal />;

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <ConfigProvider theme={theme}>
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <Routes>
            <Route element={<DefaultLayout />}>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<PasswordResetForm />} />
            </Route>
            <Route element={<UnprotectedHeaderLayout />}>
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/baa" element={<Baa />} />
              <Route path="/demo" element={<Demo />} />
            </Route>
            <Route element={<AuthLayout />}>
              <Route path="/analytics" element={<AnalyticsPage />} />
              <Route path="/agents" element={<Agent />} />
              <Route path="/home" element={<HomePage />}>
                <Route index element={<AllNotes />} />
                <Route path="clients/:clientUuid" element={<ClientNotes />} />
              </Route>
              <Route path="/thank-you" element={<ThankYou />} />
              <Route path="/" element={<HomePage />} />
            </Route>
            <Route path="/notesforMobile" element={<NotesForMobile />}>
              <Route index element={<AllNotes />} />
              <Route path="clients/:clientUuid" element={<ClientNotes />} />
            </Route>
          </Routes>
        </IntercomProvider>
      </ConfigProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
