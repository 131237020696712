import { Collapse, Flex, Steps } from "antd";
import styles from "./tutorial.module.scss";
import HowToTell from "./HowToTell.component";
import { useAppSelector } from "../../hooks/redux-hooks";

const Tutorial = () => {
  const component = "tutorial";

  const { isMobilePath } = useAppSelector((state) => state.appState);

  const Description = ({
    description,
    img,
  }: {
    description: string;
    img?: string;
  }) => {
    return <Flex vertical gap={10}>
      <p className={styles[`${component}__step-description`]}>{description}</p>
      {img && <img src={img} alt="Capture Session" className={styles[`${component}__step-image`]} />}
    </Flex>;
  }

  const Title = ({ title }: { title: string }) => {
    return <p className={styles[`${component}__step-title`]}>{title}</p>;
  }

  return (
    <Flex vertical className={styles[`${component}__container`]}>
      <Collapse
        defaultActiveKey={["1"]}
        className={styles[`${component}__container-tutorial`]}
        items={[
          {
            key: "1",
            label: "How to get started in 3 steps",
            children: (
              <Steps
                current={-1}
                direction="vertical"
                className={styles[`${component}__step`]}
                items={[
                  {
                    title: <Title title="CREATE NOTE" />,
                    description: (
                      <Description description="Click the Start A Session button and choose how you want to create your note: Live Session, Dictate, Upload, or Describe." />
                    ),
                  },
                  {
                    title: <Title title="Fill in note information" />,
                    description: (
                      <Description description="Fill in the details of the note when the drawer opens." />
                    ),
                  },
                  {
                    title: <Title title="Generate and Review Note" />,
                    description: (
                      <Description description="Wait until the note generates, and then proceed to review the note." />
                    ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
      {!isMobilePath && <HowToTell />}
    </Flex>
  );
};

export default Tutorial;
