import { Drawer, Row, Button, Flex, message } from "antd";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import { setAITemplateModalState, setTemplateOpenedFromNoteModal } from "../../../slices/appStateSlice";
import List from "./List";
import Create from "./Create";
import { setRecordingDetail } from "../../../slices/recordingsSlice";
import { createCustomNote, getCustomNoteList, updateCustomNote } from "../../../service/notes.service";

export const AITemplateModal = () => {
  const dispatch = useAppDispatch();
  const { isAITemplateModalVisible } = useAppSelector((state) => state.appState);
  const [selectedTab, setSelectedTab] = React.useState<"list" | "create">("list");
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = React.useState(true);
  const [currentTemplate, setCurrentTemplate] = React.useState<{ id: number, pk: number, name: string, document: string, settings: { is_addon: boolean, is_cpt_codes: boolean } } | null>(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const templateOpenedFromNoteModal = useAppSelector((state) => state.appState.templateOpenedFromNoteModal);
  const { recordingDetail } = useAppSelector((state) => state.recordings);
  const [isLoading, setIsLoading] = React.useState(false);
  const [customNoteList, setCustomNoteList] = React.useState<any[]>([]);
  const [isListLoading, setIsListLoading] = React.useState(false);

  const fetchCustomNoteList = async () => {
    setIsListLoading(true);
    const response = await getCustomNoteList();
    const data = await response.data;    
    setCustomNoteList([...data]);
    setIsListLoading(false);
  }

  React.useEffect(() => {
    fetchCustomNoteList();
  }, []);
  
  function handleEditTemplate(template: any) {
    setIsEditing(true);
    setCurrentTemplate(template);
    setSelectedTab("create");
  }
  
  const drawerFooter = {
    create: (
      <Flex gap={16} justify="flex-end">
        <Button type="default" onClick={() => {
          setSelectedTab("list");
          setCurrentTemplate(null);
        }}>
          Go back
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            try {
              setIsLoading(true);
              if (currentTemplate) {
                if (isEditing) {
                  await updateCustomNote(currentTemplate.pk, { 
                    name: currentTemplate.name, 
                    document: currentTemplate.document,
                    settings: currentTemplate.settings
                  });
                  await fetchCustomNoteList();
                  message.success("Changes saved");
                } else {
                  const { name, document, settings } = currentTemplate;
                  await createCustomNote({ name, document, settings }).then(async (res) => {
                    message.success("Template created");
                    await fetchCustomNoteList();

                    if (templateOpenedFromNoteModal) {
                      dispatch(setTemplateOpenedFromNoteModal(false));
                      dispatch(setAITemplateModalState(false));
                      dispatch(
                        setRecordingDetail({
                          ...recordingDetail,
                          note_type: res.data.type_id,
                          note_type_info: {
                            id: res.data.type_id,
                            name: res.data.name,
                          }
                        })
                      );
                    }
                  });
                }
                setSelectedTab("list");
                setCurrentTemplate(null);
              }
            } catch (err) {
              console.error(err);
              message.error(isEditing ? "Failed to update template" : "Failed to create template");
            } finally {
              setIsLoading(false);
            }
          }}
          loading={isLoading}
          disabled={isPrimaryButtonDisabled}
        >
          {isEditing ? "Save changes" : "Create Template"}
        </Button>
      </Flex>
    ),
    list: customNoteList.length === 0 ? null : (
      <Flex gap={16} justify="flex-end">
        <Button type="default" onClick={() => dispatch(setAITemplateModalState(false))}>
          Close
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setSelectedTab("create");
          }}
        >
          Create Template
        </Button>
      </Flex>
    )
  }

  return (
    <Drawer
      closable={false}
      open={isAITemplateModalVisible}
      onClose={() => dispatch(setAITemplateModalState(false))}
      width={600}
      styles={{
        body: {
          padding: "0",
        }
      }}
      footer={drawerFooter[selectedTab]}
    >
      <>
        {selectedTab === "list" && 
        <List 
          setSelectedTab={setSelectedTab} 
          handleEditTemplate={handleEditTemplate} 
          customNoteList={customNoteList}
          fetchCustomNoteList={fetchCustomNoteList}
          isListLoading={isListLoading}
        />}
        {selectedTab === "create" &&
          <Create
            setSelectedTab={setSelectedTab}
            setIsPrimaryButtonDisabled={setIsPrimaryButtonDisabled}
            setCurrentTemplate={setCurrentTemplate}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            currentTemplate={currentTemplate}
          />}
      </>
    </Drawer>
  )
};
