export const NOTE_TEMPLATE = `1. Chief Complaint
   - 'I'm worried about what they're going to think about me because of everything that's happened to me.'
2. Mental Status Examination
   - Speech: Normal rate, rhythm, and volume.
   - Mood: "I'm doing better."
   - Affect: Restricted range, appropriate, and flat quality.
   - Thought Content: No delusions, no suicidal or homicidal ideation, preoccupied with self-worth.
   - Thought Process: Logical and goal-directed.
   - Associations: Intact.
   - Perceptions: No hallucinations reported.
   - Judgment: Fair; evidenced by seeking employment and reconnecting with family.
   - Insight: Fair; recognizes improvement and ongoing challenges.
   - Cognition: Grossly intact.
   - Orientation: Alert and oriented to person, place, time, and situation.
3. History of Present Illness and Presenting Issues
   - [Name] is a 50-year-old male presenting with concerns about social interactions and self-worth following a period of unemployment and depression.
   - Context: Recently reconnected with family and sought additional work hours.
   - Current Situation: Actively seeking employment and managing social anxiety.
   - Key Themes:
     1. Employment Anxiety: Concerns about explaining employment gaps.
     2. Social Reconnection: Reconnecting with family and friends.
     3. Self-Worth: Struggles with self-perception and confidence.
4. Strengths
   - Demonstrates resilience by actively seeking employment and reconnecting with family. Shows willingness to engage in therapeutic exercises and role-playing to improve social interactions.
5. Limitations
   - Experiences anxiety related to social interactions and employment history. Displays a tendency to assume negative judgments from others, impacting self-esteem.
6. Interventions/Assessment
   - Engaged in cognitive restructuring to address negative automatic thoughts.
   - Practiced role-playing to enhance social confidence.
   - Reinforced the use of action plans and worksheets to track progress.
7. Engagement and Tone
   - The patient participated actively in the session, demonstrating openness to therapeutic exercises.
   - Receptive to feedback and willing to explore new strategies for managing anxiety and self-worth.
8. Risk Assessment
   - Risk Score: Low
   - Rationale: No current suicidal or homicidal ideation; actively engaged in treatment and goal-setting.
   - Progress Summary: Patient shows improvement in mood and engagement with therapeutic activities.
9. Patient's Next Steps/Goals
   - Continue applying for jobs with a focus on customer service.
   - Attend daughter's barbecue and practice social engagement strategies.
   - Maintain a list of personal achievements to reinforce self-worth.
10. Overall Next Steps
   1. Continue cognitive-behavioral therapy sessions to address social anxiety and self-worth.
   2. Encourage ongoing participation in family activities to strengthen social connections.
   3. Next session scheduled in one week per usual on this particular date/time.`;