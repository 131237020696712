import { message } from "antd";

export const checkMicrophoneMuteStatus = async (stream: MediaStream, setIsMicrophoneMuted: (value: boolean) => void) => {
  function isMuted() {
    setIsMicrophoneMuted(true);

    try {
      if (navigator.vibrate) {
        navigator.vibrate(1000);
      }

      const mp3_url = "/assets/beep.mp3";
      const audio = new Audio(mp3_url);
      audio.play().catch(error => {
        console.error("Failed to play audio alert:", error);
      });
    } catch (error) {
      console.error("Error in isMuted function:", error);
    }
  }

  try {
    const audioTrack = stream.getAudioTracks()[0];

    if (!audioTrack) {
      throw new Error("No active audio stream detected. Please check your audio settings and try again.");
    }

    // Check initial mute status
    if (!audioTrack.enabled || audioTrack.muted) {
      isMuted();
    }

    // Monitor for mute changes
    const handleMute = () => {
      isMuted();
    };

    const handleUnmute = () => {
      setIsMicrophoneMuted(false);
      message.success("Microphone is now active");
    };

    audioTrack.addEventListener('mute', handleMute);
    audioTrack.addEventListener('unmute', handleUnmute);

    // Cleanup event listeners
    return () => {
      audioTrack.removeEventListener('mute', handleMute);
      audioTrack.removeEventListener('unmute', handleUnmute);
    };
  } catch (error) {
    console.error("Error in checkMicrophoneMuteStatus:", error);
    throw error;
  }
};