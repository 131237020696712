import { Badge } from "antd";
import { getNotesAndBadge } from "../../domain/notes.domain";

type Props = {
  note_type?: number | null | undefined;
  note_type_info?: {
    id: number;
    name: string;
  };
};

export default function NoteTypeBadge({ note_type, note_type_info }: Props) {
  if (note_type_info?.name) {
    const { badgeStyle } = getNotesAndBadge(note_type_info.id > 13 ? 0 : note_type_info.id);
    return <Badge count={note_type_info.name} showZero className={badgeStyle} />;
  }

  if (note_type !== null && note_type !== undefined) {
    const { name, badgeStyle } = getNotesAndBadge(note_type);
    return <Badge count={name} showZero className={badgeStyle} />;
  }
  return null;
}
