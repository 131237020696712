import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Flex, Select, Tag, Typography } from "antd";
import { NoteType } from "../../domain/notes.domain";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { useIsMobileView } from "../../hooks/ui-hook";
import { setAITemplateModalState, setTemplateOpenedFromNoteModal } from "../../slices/appStateSlice";
import { setRecordingDetail } from "../../slices/recordingsSlice";
import styles from "./createnotemodal.module.scss";
import { getCustomNoteList } from "../../service/notes.service";
import React from "react";

const NoteTypeSelector = ({ noteTypes }: { noteTypes: { name: string, type: string }[] }) => {
  const componentName = "createnotemodal";
  const dispatch = useAppDispatch();
  const isMobile = useIsMobileView();
  const { isEditNote } = useAppSelector((state) => state.appState);
  const { recordingDetail } = useAppSelector((state) => state.recordings);
  const [customNoteList, setCustomNoteList] = React.useState<any[]>([]);
  const { templateOpenedFromNoteModal } = useAppSelector((state) => state.appState);

  const fetchCustomNoteList = async () => {
    const response = await getCustomNoteList();
    let data = await response.data;
    data = data.filter((item: any) => item.finished_generating === true)
    setCustomNoteList(data);
  }

  React.useEffect(() => {
    fetchCustomNoteList();
  }, [templateOpenedFromNoteModal]);

  const allNoteTypes = [
    {
      label: "Custom AI Templates",
      title: "Custom AI Templates", 
      options: customNoteList.map((note: any) => ({
        label: note.name,
        value: note.id,
      }))
    },
    {
      label: "Nudge Templates",
      title: "Nudge Templates",
      options: noteTypes.map((note: any) => ({
        label: note.name,
        value: NoteType[note.type],
      }))
    }
  ];

  return (
    <Col span={24} className={`${styles[`${componentName}__modal-row`]}`}>
      <Select
        value={recordingDetail?.note_type}
        listHeight={isMobile ? 160 : 256}
        disabled={isEditNote}
        onChange={(value) => {
          dispatch(
            setRecordingDetail({
              ...recordingDetail,
              note_type: value,
              modality_type: value === NoteType.CONSULT_NOTE ? "write" : recordingDetail.modality_type,
            })
          );
        }}
        showSearch
        placeholder="Select a note type"
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? "").includes(input?.toLowerCase())
        }
        // optionRender={(note) => {
        //   return (
        //     <Flex gap={10}>
        //       <Typography.Text>{note.label}</Typography.Text>
        //       {isTemplate(Number(note?.value)) ? <Tag bordered={false}>CUSTOM</Tag> : null}
        //     </Flex>
        //   );
        // }}
        dropdownRender={(menu) => (
          <div className="client">
            {menu}
            {!isEditNote ? (
              <>
                <Divider style={{ margin: "4px 0" }} />
                <Flex gap={10}>
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      if (isMobile){
                        alert('This feature is available on Desktop')
                      } else {
                        dispatch(setAITemplateModalState(true));
                        dispatch(setTemplateOpenedFromNoteModal(true));
                      }
                    }}
                  >
                    Add Template
                  </Button>
                </Flex>
              </>
            ) : null}
          </div>
        )}
        className={`${styles[`${componentName}__modal-row-field`]}`}
        options={allNoteTypes}
      />
    </Col>
  );
};

export default NoteTypeSelector;
