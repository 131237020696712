import { useCallback, useEffect } from "react";
import { useAppSelector } from "./redux-hooks";
import { useIntercom } from "react-use-intercom";
import { useIsMobileView } from "./ui-hook";

const useIntercomWithData = () => {
  const isMobile = useIsMobileView();
  const user = useAppSelector((state) => state.user);
  const { userInfo } = useAppSelector((state) => state.auth);
  const { boot, shutdown, showNewMessage } = useIntercom();

  const bootWithData = useCallback(() => {
    if (!window?.Intercom || typeof window?.Intercom !== "function") return;

    const userType = userInfo.group_name 
      ? 'group_user'
      : userInfo.is_paying 
        ? 'pro_user' 
        : 'unpaid_user';

    boot({
      userId: user.data?.id,
      email: user.data?.email,
      name: user.data?.name,
      userHash: user.data?.intercom_user_hash,
      customAttributes: {
        user_type: userType,
        group_name: userInfo.group_name || undefined,
        is_paying: userInfo.is_paying,
        total_recordings: userInfo.total_recordings
      }
    });
  }, [
    boot,
    user.data?.id,
    user.data?.email,
    user.data?.name,
    user.data?.intercom_user_hash,
    userInfo
  ]);

  useEffect(() => {
    // Only show Intercom for non-paying users and group users
    const shouldShowIntercom = !userInfo.is_paying || userInfo.group_name;
    
    if (shouldShowIntercom) {
      bootWithData();
      // Only auto-show on desktop for new users
      if (!isMobile && userInfo.total_recordings === 0) {
        setTimeout(() => showNewMessage(), 3000);
      }
    } else {
      shutdown();
    }
  }, [userInfo, bootWithData, showNewMessage, shutdown, isMobile]);

  return { bootWithData, shutdown };
};

export default useIntercomWithData;
