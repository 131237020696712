import { Button, Divider, message, Popconfirm, Skeleton, Typography } from "antd";
import { useAppDispatch } from "../../../hooks/redux-hooks";
import { deleteCustomNote } from "../../../service/notes.service";
import { setAITemplateModalState } from "../../../slices/appStateSlice";
import styles from "./AITemplateModal.module.scss";
import { AITemplateModalProps } from "./types";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const List = ({ setSelectedTab, handleEditTemplate, customNoteList, fetchCustomNoteList, isListLoading }: AITemplateModalProps &
{ handleEditTemplate: any, customNoteList: { id: number, name: string, document: string, finished_generating: boolean }[], fetchCustomNoteList: () => void, isListLoading: boolean }
) => {
  const componentName = "ai-template-modal";
  const dispatch = useAppDispatch();

  return (
    <>
      <div className={styles[`${componentName}__header`]}>
        <div className={styles[`${componentName}__header-container`]}>
          <button onClick={() => dispatch(setAITemplateModalState(false))} className={styles[`${componentName}__header-close-button`]}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
          </button>

          <h3>Custom AI Templates</h3>
        </div>
        <Button type="primary" onClick={() => setSelectedTab("create")} icon={<PlusOutlined />} aria-label="Create Template" />
      </div>
      <Divider style={{ marginBlock: "0" }} />
      {isListLoading ?
        <div style={{ paddingInline: "24px", marginTop: "24px", display: "flex", flexDirection: "column", gap: "16px" }}>
          <Skeleton.Button active style={{ width: "550px", height: "52px", borderRadius: "16px" }} />
          <Skeleton.Button active style={{ width: "550px", height: "52px", borderRadius: "16px" }} />
          <Skeleton.Button active style={{ width: "550px", height: "52px", borderRadius: "16px" }} />
        </div>
        : customNoteList.length === 0 ? (
        <div className={styles[`${componentName}__empty-state-container`]}>
          <div className={styles[`${componentName}__empty-state-icon-container`]}>
          <svg xmlns="http://www.w3.org/2000/svg" className={styles[`${componentName}__empty-state-icon`]} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.098 19.902a3.75 3.75 0 0 0 5.304 0l6.401-6.402M6.75 21A3.75 3.75 0 0 1 3 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 0 0 3.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008Z" />
          </svg>
        </div>

        <div className={styles[`${componentName}__empty-state-text`]}>
          <Typography.Title level={4}>
            No custom AI template created yet
          </Typography.Title>
          <Typography.Text>
            Paste your ideal note and let Nudge's AI create a custom template for you.
          </Typography.Text>
        </div>

        <div className={styles[`${componentName}__empty-state-button-container`]}>
          <Button type="primary" onClick={() => setSelectedTab("create")}>
            Create Template
          </Button>
          </div>
        </div>
      ) : (
        <div className={styles[`${componentName}__list-container`]}>
          {
            customNoteList.map((template: any) => (
              <div key={template.id} className={styles[`${componentName}__list-item-container`]}>
                <div className={styles[`${componentName}__list-item`]}>
                  <button onClick={() => handleEditTemplate(template)}>
                    {template.name}
                  </button>
                  {template.finished_generating ? (
                    <div className={styles[`${componentName}__list-item-actions`]}>
                      <Popconfirm
                        title="Delete Template"
                      description="Are you sure to delete this template?"
                      onConfirm={async (e) => {
                        e?.stopPropagation();
                        await deleteCustomNote(template.pk).then((res) => {
                          message.success("Template deleted");
                          fetchCustomNoteList();
                        }).catch((err) => {
                          message.error("Failed to delete template");
                          console.log(err);
                        });
                      }}
                      okText="Yes"
                      onCancel={(e) => e?.stopPropagation()}
                      cancelText="No"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                      </svg>
                    </Popconfirm>
                    <button onClick={() => handleEditTemplate(template)}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                      </svg>
                    </button>
                  </div>
                  ) : (
                    <div className={styles[`${componentName}__list-item-actions`]}>
                      <Typography.Text>
                        Generating <LoadingOutlined />
                      </Typography.Text>
                    </div>
                  )}
                </div>
                <p className={styles[`${componentName}__list-item-document`]}>
                  {template.document}
                </p>
              </div>
            ))
          }
        </div>
      )}
    </>
  )
}

export default List;