import React, { useState } from 'react';
import {
  Layout,
  Menu,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Card,
  Upload,
  Tag,
  Space,
  Typography,
} from 'antd';
import {
  UserOutlined,
  BookOutlined,
  PhoneOutlined,
  HistoryOutlined,
  ApiOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import styled from 'styled-components';

const { Sider, Content } = Layout;
const { Title } = Typography;
const { TextArea } = Input;

// Mock data
const mockAgents = [
  {
    key: '1',
    name: 'Customer Service Bot',
    type: 'Inbound',
    voice: 'Female (en-US)',
    phone: '+1 (555) 123-4567',
  },
  {
    key: '2',
    name: 'Sales Agent',
    type: 'Outbound',
    voice: 'Male (en-US)',
    phone: '+1 (555) 987-6543',
  },
];

const mockKnowledgeBase = [
  {
    key: '1',
    title: 'Product Manual',
    dateAdded: '2024-03-15',
    size: '2.5 MB',
  },
  {
    key: '2',
    title: 'FAQ Document',
    dateAdded: '2024-03-14',
    size: '1.2 MB',
  },
];

const mockPhoneNumbers = [
  {
    key: '1',
    number: '+1 (555) 123-4567',
    type: 'Toll-Free',
    status: 'Active',
    assignedTo: 'Customer Service Bot',
  },
  {
    key: '2',
    number: '+1 (555) 987-6543',
    type: 'Local',
    status: 'Active',
    assignedTo: 'Sales Agent',
  },
];

const mockCallHistory = [
  {
    key: '1',
    date: '2024-03-15 14:30',
    type: 'Inbound',
    number: '+1 (555) 123-4567',
    duration: '5:23',
    status: 'Completed',
    agent: 'Customer Service Bot',
  },
  {
    key: '2',
    date: '2024-03-15 13:15',
    type: 'Outbound',
    number: '+1 (555) 987-6543',
    duration: '3:45',
    status: 'Completed',
    agent: 'Sales Agent',
  },
];

const integrations = {
  crm: [
    { name: 'Salesforce', connected: true },
    { name: 'Airtable', connected: false },
    { name: 'Asana', connected: false },
    { name: 'GoHighLevel', connected: true },
  ],
  emr: [
    { name: 'IntakeQ', connected: false },
    { name: 'AdvancedMD', connected: true },
    { name: 'TherapyNotes', connected: false },
  ],
};

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`;

const StyledSider = styled(Sider)`
  background: #fff;
`;

const ContentWrapper = styled(Content)`
  padding: 24px;
  background: #fff;
  min-height: 280px;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const StyledTitle = styled(Title)`
  margin: 0 !important;
`;

const Agent: React.FC = () => {
  const [selectedMenu, setSelectedMenu] = useState('1');
  const [isCreateAgentModalOpen, setIsCreateAgentModalOpen] = useState(false);
  const [isKnowledgeBaseModalOpen, setIsKnowledgeBaseModalOpen] = useState(false);

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      icon: <UserOutlined />,
      label: 'Agents',
      style: { textAlign: 'left' }
    },
    {
      key: '2',
      icon: <BookOutlined />,
      label: 'Knowledge Base',
      style: { textAlign: 'left' }
    },
    {
      key: '3',
      icon: <PhoneOutlined />,
      label: 'Phone Numbers',
      style: { textAlign: 'left' }
    },
    {
      key: '4',
      icon: <HistoryOutlined />,
      label: 'Call History',
      style: { textAlign: 'left' }
    },
    {
      key: '5',
      icon: <ApiOutlined />,
      label: 'Integrations',
      style: { textAlign: 'left' }
    },
  ];

  const renderContent = () => {
    switch (selectedMenu) {
      case '1':
        return (
          <>
            <PageHeader>
              <StyledTitle level={4}>AI Agents</StyledTitle>
              <Button type="primary" onClick={() => setIsCreateAgentModalOpen(true)}>
                Create Agent
              </Button>
            </PageHeader>
            <Table
              columns={[
                { title: 'Agent Name', dataIndex: 'name', key: 'name' },
                { title: 'Agent Type', dataIndex: 'type', key: 'type' },
                { title: 'Voice', dataIndex: 'voice', key: 'voice' },
                { title: 'Phone', dataIndex: 'phone', key: 'phone' },
              ]}
              dataSource={mockAgents}
            />
          </>
        );

      case '2':
        return (
          <>
            <PageHeader>
              <StyledTitle level={4}>Knowledge Base</StyledTitle>
              <Button type="primary" onClick={() => setIsKnowledgeBaseModalOpen(true)}>
                Add Document
              </Button>
            </PageHeader>
            <Table
              columns={[
                { title: 'Title', dataIndex: 'title', key: 'title' },
                { title: 'Date Added', dataIndex: 'dateAdded', key: 'dateAdded' },
                { title: 'Size', dataIndex: 'size', key: 'size' },
              ]}
              dataSource={mockKnowledgeBase}
            />
          </>
        );

      case '3':
        return (
          <>
            <PageHeader>
              <StyledTitle level={4}>Phone Numbers</StyledTitle>
              <Button type="primary">Add Phone Number</Button>
            </PageHeader>
            <Table
              columns={[
                { title: 'Number', dataIndex: 'number', key: 'number' },
                { title: 'Type', dataIndex: 'type', key: 'type' },
                { title: 'Status', dataIndex: 'status', key: 'status' },
                { title: 'Assigned To', dataIndex: 'assignedTo', key: 'assignedTo' },
              ]}
              dataSource={mockPhoneNumbers}
            />
          </>
        );

      case '4':
        return (
          <>
            <PageHeader>
              <StyledTitle level={4}>Call History</StyledTitle>
            </PageHeader>
            <Table
              columns={[
                { title: 'Date & Time', dataIndex: 'date', key: 'date' },
                { title: 'Type', dataIndex: 'type', key: 'type' },
                { title: 'Number', dataIndex: 'number', key: 'number' },
                { title: 'Duration', dataIndex: 'duration', key: 'duration' },
                { title: 'Status', dataIndex: 'status', key: 'status' },
                { title: 'Agent', dataIndex: 'agent', key: 'agent' },
              ]}
              dataSource={mockCallHistory}
            />
          </>
        );

      case '5':
        return (
          <>
            <PageHeader>
              <StyledTitle level={4}>Integrations</StyledTitle>
            </PageHeader>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              <Card title="CRM Integrations">
                <Space wrap>
                  {integrations.crm.map((integration) => (
                    <Tag
                      key={integration.name}
                      color={integration.connected ? 'green' : 'default'}
                      style={{ padding: '8px 16px', fontSize: '14px' }}
                    >
                      {integration.name}
                    </Tag>
                  ))}
                </Space>
              </Card>
              <Card title="EMR Integrations">
                <Space wrap>
                  {integrations.emr.map((integration) => (
                    <Tag
                      key={integration.name}
                      color={integration.connected ? 'green' : 'default'}
                      style={{ padding: '8px 16px', fontSize: '14px' }}
                    >
                      {integration.name}
                    </Tag>
                  ))}
                </Space>
              </Card>
            </Space>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <StyledLayout>
      <StyledSider width={200}>
        <Menu
          mode="inline"
          selectedKeys={[selectedMenu]}
          items={menuItems}
          onClick={({ key }) => setSelectedMenu(key)}
          style={{ height: '100%' }}
        />
      </StyledSider>
      <Layout>
        <ContentWrapper>{renderContent()}</ContentWrapper>
      </Layout>

      {/* Create Agent Modal */}
      <Modal
        title="Create New Agent"
        open={isCreateAgentModalOpen}
        onCancel={() => setIsCreateAgentModalOpen(false)}
        footer={null}
      >
        <Form layout="vertical">
          <Form.Item label="Agent Type" required>
            <Select placeholder="Select agent type">
              <Select.Option value="inbound">Inbound Agent</Select.Option>
              <Select.Option value="outbound">Outbound Agent</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Agent Name" required>
            <Input placeholder="Enter agent name" />
          </Form.Item>
          <Form.Item label="Voice" required>
            <Select placeholder="Select voice">
              <Select.Option value="female-us">Female (en-US)</Select.Option>
              <Select.Option value="male-us">Male (en-US)</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Greeting Message" required>
            <TextArea 
              placeholder="Enter the greeting message that the agent will use"
              rows={4}
            />
          </Form.Item>
          <Form.Item 
            label="System Prompt" 
            required
            tooltip="This defines the agent's personality and behavior"
          >
            <TextArea 
              placeholder="Enter the system prompt that defines the agent's behavior"
              rows={6}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" block>
              Create Agent
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Add Knowledge Base Modal */}
      <Modal
        title="Add Document"
        open={isKnowledgeBaseModalOpen}
        onCancel={() => setIsKnowledgeBaseModalOpen(false)}
        footer={null}
      >
        <Form layout="vertical">
          <Form.Item label="Document Title" required>
            <Input placeholder="Enter document title" />
          </Form.Item>
          <Form.Item label="Upload Document" required>
            <Upload>
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" block>
              Upload Document
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </StyledLayout>
  );
};

export default Agent;
