import { Card, Col, Row, Typography, message } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/redux-hooks";
import { setCreateModalState, setAITemplateModalState, setOpenShare } from "../../slices/appStateSlice";
import { setRecordingDetail } from "../../slices/recordingsSlice";
import { EventType, trackEvent } from "../../utils/analytics";
import { getAnalyticsKeyOnTheBasisOfType } from "../../utils/datamanipulation.utils";
import styles from "./recordcta.module.scss";
import { useIsMobileView } from "../../hooks/ui-hook";

type RecordCTAProps = {
  captureRef?: React.RefObject<HTMLDivElement>;
};

const RecordCTA = ({ captureRef }: RecordCTAProps) => {
  const isMobile = useIsMobileView();
  const dispatch = useDispatch();

  const componentName = "RecordCTA";

  const { uploadInProgress, isDemoPath, selectedClient } = useAppSelector((state) => state.appState);
  const { recordingDetail } = useAppSelector((state) => state.recordings);

  const handleButtonClick = (type: "audio" | "dictate" | "upload" | "write") => {
      if (uploadInProgress) {
        message.warning("Please wait for the current upload to finish.");
        return;
      }
      if (isDemoPath) {
        trackEvent(getAnalyticsKeyOnTheBasisOfType(type), {
          type,
        });
      }
      dispatch(
        setRecordingDetail({
          ...recordingDetail,
          modality_type: type,
          client_id : selectedClient?.id
        }),
      );
      dispatch(setCreateModalState(true));
  };

  const openShare = () => {
    trackEvent(EventType.SHARE_MODAL_OPEN, {
      shareModalType: "share_button",
    });
    dispatch(setOpenShare(true));
  };

  const openAITemplateModal = () => {
    dispatch(setAITemplateModalState(true));
  };

  return (
    <>
      <Row
        gutter={[10, 10]}
        ref={captureRef}
        className={styles[`${componentName}__container-right-container`]}
      >
        <Col
          span={24}
          className={
            styles[`${componentName}__container-right-header-container`]
          }
        >
          <Typography
            className={styles[`${componentName}__container-right-header`]}
          >
            Generate Note
          </Typography>
        </Col>
        <Col span={24}>
          <Card
            ref={captureRef}
            className={styles[`${componentName}__container-right-card`]}
            onClick={() => {
              handleButtonClick("audio");
            }}
          >
            <div style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" className={styles[`${componentName}__container-right-card-logo`]} viewBox="0 0 24 24" fill="currentColor"><path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z" clipRule="evenodd" /></svg>
              <div style={{
                textAlign: "start",
              }}>
                <Typography
                  className={styles[`${componentName}__container-right-card-title`]}
                >
                  Start A Session
                </Typography>
                <Typography
                  className={
                    styles[`${componentName}__container-right-card-description`]
                  }
                >
                  Capture live session, dictate, upload, or describe session
                </Typography>
              </div>
            </div>
          </Card>
        </Col>

        {!isMobile ? <Col span={24}>
          <Card
            ref={captureRef}
            className={styles[`${componentName}__container-right-card`]}
            onClick={() => {
              openAITemplateModal();
            }}
          >
            <div style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}>
              <svg className={styles[`${componentName}__container-right-card-logo`]} width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#EEF2FF" />
                <path d="M12.098 27.902a3.752 3.752 0 0 0 2.652 1.099m-2.652-1.099a3.751 3.751 0 0 0 2.652 1.099m-2.652-1.099A3.75 3.75 0 0 1 11 25.25V12.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v4.072m-3.75 12.804a3.75 3.75 0 0 0 2.652-1.099m-2.652 1.099c.995 0 1.949-.396 2.652-1.099m-2.652 1.099L27.875 29c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072m-6.401 6.402 6.401-6.402m-6.401 6.402A3.75 3.75 0 0 0 18.5 25.25v-9.053m5.303 5.303 2.879-2.88c.44-.438.44-1.15 0-1.59l-3.712-3.713a1.124 1.124 0 0 0-1.59 0l-2.88 2.88m-3.75 9.053h.008v.008h-.008v-.008Z" stroke="#6366F1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <div style={{
                textAlign: "start",
              }}>
                <Typography
                  className={styles[`${componentName}__container-right-card-title`]}
                >
                  Create AI Template
                </Typography>
                <Typography
                  className={
                    styles[`${componentName}__container-right-card-description`]
                  }
                >
                  Paste an ideal note, and our AI will create a custom template
                </Typography>
              </div>
            </div>
          </Card>
        </Col>: null}
        <Col span={24} className={styles[`${componentName}__container-right-container-bottomcta`]} onClick={openShare}>
          <div className={styles[`${componentName}__container-right-container-bottomcta-text`]}><span style={{textDecoration: 'underline'}}>Share & Earn a Free Month</span> ❤️</div>
        </Col>
      </Row>
    </>
  );
};

export default RecordCTA;
